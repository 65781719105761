import styled from "@emotion/styled"
import { TransparentText } from "./transparent-text"

export const HomeWrapper = styled.section`
  background: #1e1e1e;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    z-index: 89;
  }
`

export const SlideOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
`

export const SlideButton = styled.button`
  color: #fefefe;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: lowercase;
  margin-top: 2rem;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  border: none;

  :before {
    background-color: #2497c6;
    display: block;
    content: "";
    width: 4rem;
    height: 2px;
    transform: translate(-2rem, 1px);
    transition: all 0.4s ease-in-out;
  }

  :hover {
    :before {
      width: 8rem;
    }

    color: #2497c6;
  }
`

export const SlideText = styled(TransparentText)`
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  text-transform: initial;
  font-size: 6vmin;
  line-height: 1.125;
  letter-spacing: 0;
  transition: all 0.4s ease-in-out;
  max-width: 85vw;
  position: relative;

  @media (min-width: 1024px) {
    font-size: 7vmin;
    max-width: 55vw;
  }

  :before {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '"';
    font-family: "Yantramanav", sans-serif;
    font-weight: 400;
    color: #444;
    opacity: 0.4;
    font-size: 100vw;
    transform: translate(-25%, -25%);
    z-index: -1;
  }

  :hover {
    cursor: default;

    & + ${SlideButton} {
      ::before {
        width: 8rem;
      }

      color: #2497c6;
    }
  }

  &[data-quotes="true"] {
    :before {
      content: '"';
      display: inline-block;
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
    }

    :before {
      margin-right: 100%;
      position: relative;
      top: 0.75ch;
      left: 0px;
    }
  }
`

export const Slide = styled.div`
  width: 100%;
  height: calc(100% - 8rem);
  min-height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  padding: 4rem;

  @media (max-width: 1023px) {
    padding: 2rem 1rem;
  }

  * {
    z-index: 1;
  }

  a {
    text-decoration: none;
  }

  video {
    transform: scale(1.1);
    pointer-events: none;
  }

  video,
  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw !important;
    height: 100vh !important;
    object-fit: cover;
    object-position: top center;
    z-index: 0;
  }

  :nth-of-type(1) {
    video,
    img,
    .gatsby-image-wrapper {
      @media (max-width: 640px) {
        object-position: 45% 50% !important;
      }
    }
  }

  :nth-of-type(2) {
    video,
    img,
    .gatsby-image-wrapper {
      @media (max-width: 640px) {
        object-position: 75% 50% !important;
      }
    }
  }

  :nth-of-type(4) {
    video,
    img,
    .gatsby-image-wrapper {
      @media (max-width: 640px) {
        object-position: 75% 50% !important;
      }
    }
  }

  :nth-of-type(6) {
    video,
    img,
    .gatsby-image-wrapper {
      @media (max-width: 640px) {
        object-position: 75% 50% !important;
      }
    }
  }

  :nth-of-type(8) {
    video,
    img,
    .gatsby-image-wrapper {
      @media (max-width: 640px) {
        object-position: 75% 50% !important;
      }
    }
  }

  ${SlideText} {
    opacity: 0;
    transform: translateY(8rem);
  }

  &.active {
    ${SlideText} {
      opacity: 1;
      transform: translateY(0);
    }
  }

  a {
    position: relative;
  }
`

export const NextButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  background: transparent;
  border: none;
  color: #fefefe;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease-in-out;

  @media (max-width: 1023px) {
    display: none;
  }

  :after {
    display: block;
    content: "";
    background-color: #fefefe;
    width: 3rem;
    height: 1px;
    margin-left: 1rem;
    transition: all 0.2s ease-in-out;
  }

  :hover {
    cursor: pointer;

    :after {
      width: 5rem;
    }
  }

  &[disabled],
  &:disabled {
    color: #606060;

    :after {
      background-color: #606060;
    }

    :hover {
      cursor: default;

      :after {
        width: 3rem;
      }
    }
  }
`

export const ProgressContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0;
  z-index: 1;

  @media (min-width: 1024px) {
    bottom: 4rem;
    right: 8rem;
  }
`

export const ProgressBarWrapper = styled.div`
  width: 200px;
  height: 1px;
  background: #ccc;
  position: relative;
`

export const ProgressBar = styled.div`
  width: 50px;
  height: 3px;
  background: #fefefe;
  position: absolute;
  transform: translateY(-1px);
`

export const ProgressCounter = styled.div`
  position: absolute;
  right: calc(200px + 1rem);
  bottom: 0.5rem;
  display: flex;
  line-height: 0;
  z-index: 1;

  @media (min-width: 1024px) {
    display: block;
    line-height: initial;
    right: calc(200px + 6rem);
  }
`

export const ProgressCounterText = styled.p`
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 400;
  color: #fefefe;
  margin: 1.5rem 0.5rem;
  position: relative;

  @media (min-width: 1024px) {
    margin: 1.5rem 0;
  }

  @media (max-width: 1023px) {
    :first-of-type {
      :after {
        content: "/";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: calc(100% + 0.375rem);
        top: 0;
      }
    }
  }
`
