import React from "react"
import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Topbar, MenuButtonLine, MenuButton } from "./header-styles"
import { css } from "@emotion/core"

const openMenu = () => {
  document.querySelector(".menu").classList.add("active")
  document.querySelector(".header__button").classList.add("open")
  document.querySelector(".menu video").play()
}

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "logo-white.png" }) {
          childImageSharp {
            fixed(height: 50, width: 50) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Topbar>
      <Link to="/">
        <Img fixed={data.file.childImageSharp.fixed} alt="" />
      </Link>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .number {
            margin-right: 2rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            color: #fefefe;
            text-decoration: none;
          }
        `}
      >
        <a className="number" href="+tel:8665321187">
          866.532.1187
        </a>
        <MenuButton className="header__button" onClick={openMenu}>
          <MenuButtonLine />
          <MenuButtonLine />
        </MenuButton>
      </div>
    </Topbar>
  )
}
